import React from 'react'

function About() {
  return (
    <>
      about page
    </>
  )
}

export default About
