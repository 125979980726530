import React from 'react'

function Generate() {
  return (
    <>
      Page to generate letter
    </>
  )
}

export default Generate
