import React from 'react'

function LandingAftConf() {
    return (
        <div style={{width:"100%",height:"90%",display:"flex",justifyContent:"center",alignItems:"center"}}>
            <h1>Application has been confirmed ...</h1>
        </div>
    )
}

export default LandingAftConf
